import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  ResetPasswordModel, ForgotPasswordClient, ForgotPasswordModel,
  ResultDTO, UserInfoClient, UsersClient, ConfirmationUserInfoModel
} from '@shared/http-clients/http-clients';
import { take, finalize, tap } from 'rxjs/operators';

export const LoginBackgroundImage = 'login-background.png';

@Injectable()
export class LoginDataService {
  private _isResettingPassword$ = new BehaviorSubject<boolean>(false);
  readonly isResettingPassword$ = this._isResettingPassword$.asObservable();

  private _isLoadingForgotPassword$ = new BehaviorSubject<boolean>(false);
  readonly isLoadingForgotPassword$ = this._isLoadingForgotPassword$.asObservable();

  private _isGeneratingNemIdParameters$ = new BehaviorSubject<boolean>(false);
  readonly isGeneratingNemIdParameters$ = this._isGeneratingNemIdParameters$.asObservable();

  private _isLoadingResponseData$ = new BehaviorSubject<boolean>(false);
  readonly isLoadingResponseData$ = this._isLoadingResponseData$.asObservable();

  private _responseData$ = new BehaviorSubject<any>(undefined);
  readonly responseData$ = this._responseData$.asObservable();

  private _isSavingCprFromPID$ = new BehaviorSubject<boolean>(false);
  readonly isSavingCprFromPID$ = this._isSavingCprFromPID$.asObservable();

  private _savingCprFromPidResult$ = new BehaviorSubject<any>(undefined);
  readonly savingCprFromPidResult$ = this._savingCprFromPidResult$.asObservable();

  private _isGettingUserInfo$ = new BehaviorSubject<boolean>(null);
  readonly isGettingUserInfo$ = this._isGettingUserInfo$.asObservable();

  constructor(
    private forgotPasswordClient: ForgotPasswordClient,
    private userInfoClient: UserInfoClient,
    private usersClient: UsersClient,
  ) { }

  forgotPassword(email: string , language: string): Observable<void> {
    this._isLoadingForgotPassword$.next(true);
    const model = new ForgotPasswordModel({ email: email , language: language });

    return this.forgotPasswordClient.post(model).pipe(
      take(1),
      finalize(() => this._isLoadingForgotPassword$.next(false))
    );
  }

  resetPassword(model: ResetPasswordModel): Observable<ResultDTO> {
    this._isResettingPassword$.next(true);

    return this.forgotPasswordClient.patch(model).pipe(
      take(1),
      finalize(() => this._isResettingPassword$.next(false))
    );
  }

  getUserInfoByUsername(username){
    this._isGettingUserInfo$.next(true);
    return this.userInfoClient.getUserInfoByUsername(username).pipe(
      take(1),
      finalize(() => this._isGettingUserInfo$.next(false))
    );
  }
  confirmUser(model: ConfirmationUserInfoModel){
    this._isGettingUserInfo$.next(true);
    return this.usersClient.confirmUser(model).pipe(
      take(1),
      finalize(() => this._isGettingUserInfo$.next(false))
    );
  }
}
