import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { DialogNotificationComponent } from './dialog-notification/dialog-notification.component';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor(
    private readonly snackBar: MatSnackBar,
    private readonly zone: NgZone,
    private readonly dialog: MatDialog
  ) { }

  default(message: string) {
    let config = new MatSnackBarConfig();
    this.show(message, {
      duration: 5000,
      panelClass: ['default-notification-overlay' , 'snack-bar']
    });
  }

  info(message: string) {
    this.show(message, {
      duration: 5000,
      panelClass: ['info-notification-overlay' , 'snack-bar']
    });
  }

  success(message: string) {
    this.show(message, {
      duration: 5000,
      panelClass: ['success-notification-overlay' , 'snack-bar']
    });
  }

  warn(message: string) {
    this.show(message, {
      duration: 5000,
      panelClass: ['warning-notification-overlay' , 'snack-bar']
    });
  }

  error(message: string) {
    if (message.length > 200) {
      this.zone.run(() => this.dialog.open(DialogNotificationComponent, {
        data: message,
        width: '500px'
      }));
    } else {
      this.show(message, {
        duration: 5000,
        panelClass: ['error-notification-overlay','snack-bar']
      });
    }
  }

  private show(message: string, configuration: MatSnackBarConfig) {
    // Need to open snackBar from Angular zone to prevent issues with its position per
    // https://stackoverflow.com/questions/50101912/snackbar-position-wrong-when-use-errorhandler-in-angular-5-and-material
    this.zone.run(() => this.snackBar.open(message, null, configuration));
  }
}
