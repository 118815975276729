import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Roles } from '@core/helpers/all-roles';
import { TenantService } from '@core/tenant/tenant.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AssignmentsGuard implements CanActivate {
  constructor(
    private tenantService: TenantService,
    private permission: NgxPermissionsService,
    private router: Router
  ) {
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      
      if(this.permission.getPermission(Roles.Admin) || this.permission.getPermission(Roles.NetworkAdmin))
          return true;
      else if (this.permission.getPermission(Roles.TreatmentProviderRepresentative) && this.tenantService.currentTenant.clinicAccessToAssignments) 
          return true;

    this.router.navigate(['/']);
    return false;
  }
  
}
