import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  SexModel, SexesClient, BookingStatusModel, BookingStatusClient,
  TreatmentProviderNetworkClient, TreatmentProviderNetworkShortModel,
  ScheduleTypeModel, AssignmentClient, RoleModel, RoleClient, TermsAndConditionsModel, TermsAndConditionsClient
} from '@shared/http-clients/http-clients';
import { finalize, tap, take, flatMap, filter, toArray, mergeMap } from 'rxjs/operators';
import { Announcement } from '@features/home/models/announcement.model';
import { ActivatedRoute } from '@angular/router';
import { TenantService } from './tenant/tenant.service';

@Injectable({
  providedIn: 'root'
})
export class CoreDataService {
  private _announcements$ = new BehaviorSubject<Announcement[]>([]);
  readonly announcements$ = this._announcements$.asObservable();

  private _sexes$ = new BehaviorSubject<SexModel[]>(undefined);
  readonly sexes$ = this._sexes$.asObservable().pipe(
    tap(sexes => sexes ? null : this.fetchSexes())
  );

  private _isFetchingSexes$ = new BehaviorSubject<boolean>(false);
  readonly isFetchingSexes = this._isFetchingSexes$.asObservable();

  private _isFetchingAllTreatmentTypes$ = new BehaviorSubject<boolean>(false);
  readonly isFetchingAllTreatmentTypes$ = this._isFetchingAllTreatmentTypes$.asObservable();

  private _allBookingStatuses$ = new BehaviorSubject<BookingStatusModel[]>(undefined);
  readonly allBookingStatuses$ = this._allBookingStatuses$.pipe(
    tap(statuses => statuses ? null : this.fetchAllBookingStatuses())
  );

  private _isFetchingAllBookingStatuses$ = new BehaviorSubject<boolean>(false);
  readonly isFetchingAllBookingStatuses$ = this._isFetchingAllBookingStatuses$.asObservable();

  private _isFetchingAllTreatmentProviderNetworks$ = new BehaviorSubject<boolean>(false);
  readonly isFetchingAllTreatmentProviderNetworks$ = this._isFetchingAllTreatmentProviderNetworks$.asObservable();


  private _isFetchingTreatmentProviderNetworkPhoneNumber$ = new BehaviorSubject<boolean>(false);
  readonly isFetchingTreatmentProviderNetworkPhoneNumber$ = this._isFetchingTreatmentProviderNetworkPhoneNumber$.asObservable();

  private _allTreatmentProviderNetworks$ = new BehaviorSubject<TreatmentProviderNetworkShortModel[]>(undefined);
  readonly allTreatmentProviderNetworks$ = this._allTreatmentProviderNetworks$.pipe(
    tap(networks => networks ? null : this.fetchAllTreatmentProviderNetworks())
  );

  private _scheduleTypes$ = new BehaviorSubject<ScheduleTypeModel[]>(undefined);
  private _isFetchingScheduleTypes$ = new BehaviorSubject<boolean>(false);
  readonly scheduleTypes$ = this._scheduleTypes$.pipe(tap(x => x ?? this.fetchScheduleTypes()));
  readonly isFetchingScheduleTypes$ = this._isFetchingScheduleTypes$.asObservable();

  private _roles$ = new BehaviorSubject<RoleModel[]>(undefined);
  private _isFetchingRoles$ = new BehaviorSubject<boolean>(false);
  readonly roles$ = this._roles$.pipe(tap(x => x ?? this.fetchRoles()));
  readonly isFetchingRoles$ = this._isFetchingRoles$.asObservable();

  private _isFetchingTermsAndConditions$ = new BehaviorSubject<boolean>(false);
  readonly isFetchingTermsAndConditions$ = this._isFetchingTermsAndConditions$.asObservable();

  language$: Observable<string>;
  private _termsAndConditions$ = new BehaviorSubject<TermsAndConditionsModel>(undefined);
  readonly termsAndConditions$ = this._termsAndConditions$.pipe(tap(x => x ?? this.getTermsAndConditions()));

  constructor(
    private sexesClient: SexesClient,
    private bookingStatusesClient: BookingStatusClient,
    private treatmentProviderNetworkClient: TreatmentProviderNetworkClient,
    private assignmentClient: AssignmentClient,
    private roleClient: RoleClient,
    private termsAndConditionsClient: TermsAndConditionsClient,
    private route: ActivatedRoute,
    private tenantService: TenantService
  ) {
  }

  addAnnouncement(announcement: Announcement) {
    if (this._announcements$.getValue().findIndex(x => x.message === announcement.message) !== -1) return;

    const newAnnoucements = [announcement, ...this._announcements$.getValue()];
    this._announcements$.next(newAnnoucements);
  }
  clearData() {
    this._roles$.next(undefined)
    this._scheduleTypes$.next(undefined)
    this._allBookingStatuses$.next(undefined)
    this._sexes$.next(undefined)
    this._announcements$.next([])
    this._termsAndConditions$.next(undefined)
  }

  removeAnnouncement(announcement: Announcement) {
    this._announcements$.pipe(
      take(1),
      flatMap(a => a),
      filter(a => a.message !== announcement.message),
      toArray()
    ).subscribe(announcements => this._announcements$.next(announcements));
  }

  private fetchSexes(): void {
    this._isFetchingSexes$.next(true);

    this.sexesClient.get().pipe(
      finalize(() => this._isFetchingSexes$.next(false))
    ).subscribe(sexes => this._sexes$.next(sexes));
  }

  private fetchAllBookingStatuses(): void {
    this._isFetchingAllBookingStatuses$.next(true);
    this.bookingStatusesClient.getAll().pipe(
      take(1),
      finalize(() => this._isFetchingAllBookingStatuses$.next(false))
    ).subscribe(statuses => this._allBookingStatuses$.next(statuses));
  }

  private fetchAllTreatmentProviderNetworks(): void {
    this._isFetchingAllTreatmentProviderNetworks$.next(true);
    this.treatmentProviderNetworkClient.getAllNetworks().pipe(
      take(1),
      finalize(() => this._isFetchingAllTreatmentProviderNetworks$.next(false))
    ).subscribe(networks => this._allTreatmentProviderNetworks$.next(networks));
  }

  private fetchScheduleTypes() {
    this._isFetchingScheduleTypes$.next(true);
    this.assignmentClient.getScheduleTypes().pipe(
      take(1),
      finalize(() => this._isFetchingScheduleTypes$.next(false))
    ).subscribe(scheduleTypes => this._scheduleTypes$.next(scheduleTypes));
  }

  private fetchRoles() {
    this._isFetchingRoles$.next(true);
    this.roleClient.getAll().pipe(
      take(1),
      finalize(() => this._isFetchingRoles$.next(false))
    ).subscribe(x => this._roles$.next(x));
  }
  fetchTreatmentProviderNetworkPhoneNumber$(): Observable<string> {
    this._isFetchingTreatmentProviderNetworkPhoneNumber$.next(true);
    return this.treatmentProviderNetworkClient.getNetworkPhoneNumber().pipe(
      take(1),
      finalize(() => this._isFetchingTreatmentProviderNetworkPhoneNumber$.next(false))
    )
  }

  getTermsAndConditions() {
    this._isFetchingTermsAndConditions$.next(true);

    this.route.queryParams.pipe(
      mergeMap(params => {
                let codeType = "";
        let codeEntity = "";
        if (params['codeEntity'] || params['codeType']) {
          codeType = atob(params['codeType']);
          codeEntity = atob(params['codeEntity']);
        } else {
          codeType = 'BOOKINGS';
          codeEntity = 'PATIENT';
        }
        return this.termsAndConditionsClient.get(this.tenantService.currentTenant.urlPattern, codeType, codeEntity);
      }),
      take(1),
      finalize(() => this._isFetchingTermsAndConditions$.next(false))).subscribe(result => {
        this._termsAndConditions$.next(result);
      })
  }

}
