import browser from 'browser-detect';
import { Component, OnInit, OnDestroy, Inject, Injector, ChangeDetectionStrategy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable, combineLatest, BehaviorSubject } from 'rxjs';
import { environment as env } from '../../environments/environment';

import {
  routeAnimations,
  AppState,
  selectSettingsStickyHeader,
  selectSettingsLanguage,
  selectEffectiveTheme,
  LocalStorageService
} from '@core/core.module';
import {
  actionSettingsChangeAnimationsPageDisabled,
  actionSettingsChangeLanguage,
  actionSettingsChangeTheme
} from '@core/settings/settings.actions';
import { UserInfo, API_BASE_URL, Permission } from '@shared/http-clients/http-clients';
import { map, switchMap, filter, distinctUntilChanged, take } from 'rxjs/operators';
import { Router, RouterOutlet } from '@angular/router';
import { RouterDataService } from '@core/router/router-data.service';
import * as moment from 'moment-timezone';
import { Roles } from '@core/helpers/all-roles';
import { MenuItem } from '@core/models/menu-item.model';
import { MatDialog } from '@angular/material/dialog';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { CoreComponentBase } from '@core/bases/core-component-base';
import { DOCUMENT } from '@angular/common';
import { Language } from '@core/settings/settings.model';
import { TokenData } from '@core/auth/auth.models';
import { AUTH_KEY } from '@core/auth/auth.service';
import { VideoChatService } from '../features/video-chat/services/video-chat.service';
import { VideoCallHelperService } from '@core/helpers/video-call.helper.service';

@Component({
  selector: 'kailo-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [routeAnimations],
})
export class AppComponent extends CoreComponentBase implements OnInit, OnDestroy {
  isProd = env.production;
  envName = env.envName;
  version = env.versions.app;
  year = new Date().getFullYear();
  logo;
  logoVideoCall;
  daFlag;
  deFlag;
  enFlag;
  mkFlag;
  sqFlag;
  languages = ['en', 'de', 'sk', 'fr', 'es', 'pt-br', 'zh-cn', 'he', 'sq'];
  phoneNumber:string = '78 79 39 40';
  navigation: MenuItem[] = [
    { link: '', label: 'kailo.menu.home', id: 'homeMenu', ngxPermissionsOnly: [Roles.Patient, 'Permissions.TreatmentProviderDashboard.Read']},
    { link: 'bookings', label: 'kailo.menu.bookings', id: 'bookingsMenuItem', ngxPermissionsExcept: [Roles.IT] },
    { link: 'cases', label: this.permission.getPermission(this.Roles.Patient) ? 'kailo.menu.cases.patient' : 'kailo.menu.cases', id: 'casesMenuItem', ngxPermissionsExcept: [Roles.IT]},
    { link: this.tenantService.currentTenant.clinicSearchAccess ? 'clinic-search':'search', label: 'kailo.menu.search', id: 'searchMenuItem', ngxPermissionsExcept: [Roles.IT] },
    { link: 'calendar', label: 'kailo.menu.calendar', id: 'calendarMenuItem',  ngxPermissionsExcept: [Roles.Patient, Roles.IT]},
    { link: 'users', label: 'kailo.menu.users', id: 'usersMenuItem',  ngxPermissionsOnly: [Roles.TreatmentProviderEmployee, Roles.TreatmentProviderRepresentative, Roles.IT]},
    { link: 'reports', label: 'kailo.menu.reports', id: 'reportsMenuItem', ngxPermissionsOnly: [Roles.Admin, Roles.NetworkAdmin ]},
    {
      link: '', label: 'kailo.menu.adminPanel',id: 'adminPanel', ngxPermissionsOnly: [Roles.Admin, Roles.NetworkAdmin, Roles.Nurse, ],
      submenus: [
        { link: 'companies', label: 'kailo.menu.companies', id: 'companiesSubMenuItem',  ngxPermissionsOnly: [Roles.Admin, Roles.NetworkAdmin, Roles.Nurse]},
        { link: 'assignments', label: 'kailo.menu.assignments', id: 'assignmentsSubMenuItem',  ngxPermissionsOnly: [Roles.Admin, Roles.NetworkAdmin] },
        { link: 'users', label: 'kailo.menu.users', id: 'usersSubMenuItem', ngxPermissionsOnly: [Roles.Admin, Roles.NetworkAdmin, Roles.Nurse]},
        { link: 'treatmentproviders',id: 'treatmentProvidersSubMenuItem', label: 'kailo.menu.treatmentProviders', ngxPermissionsOnly: [Roles.Admin, Roles.NetworkAdmin, Roles.Nurse] },
        { link: 'portalnotifications',id: 'portalnotificationSubMenuItem', label: 'kailo.menu.portalNotifications', ngxPermissionsOnly: [Roles.Admin, Roles.NetworkAdmin] },
        { link: 'notifications',id: 'notificationSubMenuItem', label: 'kailo.menu.notification-templates', ngxPermissionsOnly: [Roles.Admin, Roles.NetworkAdmin] }
      ]
    }
  ];
  navigationSideMenu = [
    ...this.navigation,
    { link: 'settings', label: 'kailo.menu.settings' }
  ];
  baseUrl: string;

  isAuthenticated$: Observable<boolean>;
  userInfo$: Observable<UserInfo>;
  userProfilePicture$: Observable<string>;
  stickyHeader$: Observable<boolean>; // is this important for our case or the header will remain the same, maybe for mobile is different
  language$: Observable<string>;
  theme$: Observable<string>;
  shouldHideToolbar$: Observable<boolean>;
  shouldHideFooter$: Observable<boolean>;
  contentClass$: Observable<string>;
  routeTitle$: Observable<string>;
  contentMarginTop$: Observable<string>;
  isPageLoading$: Observable<boolean>
  preferredLanguage$: Observable<string>;
  currentUserId:number
  constructor(
    injector: Injector,
    private store: Store<AppState>,
    private routerData: RouterDataService,
   
    public dialog: MatDialog,
    private videoCallHelperService: VideoCallHelperService,
    private storageService: LocalStorageService,
    public router: Router,
    @Inject(DOCUMENT) private _document: HTMLDocument,
    @Inject(API_BASE_URL) baseUrl?: string,

  ) {
    super(injector);
    this.baseUrl = baseUrl ? baseUrl : env.apiBaseUrl;
    if(this.tenantService.currentTenant.clinicAccessToAssignments)
      this.navigation.push({ link: 'assignments', label: 'kailo.menu.assignments', id: 'assignmentsSubMenuItem',  ngxPermissionsOnly: [Roles.TreatmentProviderRepresentative]});
  }

  private static isIEorEdgeOrSafari() {
    return ['ie', 'edge', 'safari'].includes(browser().name);
  }

  ngOnInit(): void {
    moment.tz.setDefault('Europe/Copenhagen');

    if (AppComponent.isIEorEdgeOrSafari()) {
      this.store.dispatch(
        actionSettingsChangeAnimationsPageDisabled({
          pageAnimationsDisabled: true
        })
      );
    }

    this.stickyHeader$ = this.store.pipe(select(selectSettingsStickyHeader));
    this.language$ = this.store.pipe(select(selectSettingsLanguage));
    this.theme$ = this.store.pipe(select(selectEffectiveTheme));
    this.isAuthenticated$ = this.auth.isAuthenticated$;
    this.userInfo$ = this.session.userInfo$;
    this.userProfilePicture$ = this.userInfo$.pipe(
      map(userInfo => userInfo && userInfo.profilePicture ? userInfo.profilePicture : undefined)
    );

    this.userInfo$.pipe(
      untilDestroyed(this),
      map(userInfo => 
        (this.storageService.getItem("preferredLanguage") ? this.storageService.getItem("preferredLanguage") :
         userInfo && userInfo.preferedLanguage ? userInfo.preferedLanguage :
         this.tenantService.currentTenant.preferedLanguage) as Language)
    ).subscribe(language =>{
      this.storageService.setItem('preferredLanguage', language);
      this.store.dispatch(actionSettingsChangeLanguage({ language: language }))
    }
    );

    // TODO: Why is this here? Do we need to prevent dialogs when the user is NOT Authenticated?
    // this.initMatDialogAfterOpened();

    this.initCurrentTenant();

    this.contentClass$ = this.videoCallHelperService.isInVideoCallRoom$
    .pipe(
      map((isInVideoCallRoom) => isInVideoCallRoom ? 'video-call-content' : 'content')
    );

    this.shouldHideToolbar$ = combineLatest([
      this.routerData.activatedRouteData$,
      this.auth.hasUserAcceptedTermsConditions$,
      this.videoCallHelperService.isInVideoCallRoom$
    ]).pipe(
      map(([routeData, hasAccepted, isInVideoCallRoom]) => routeData && routeData.hideToolbar || !hasAccepted || isInVideoCallRoom)
    );

    this.contentMarginTop$ = combineLatest([
      this.shouldHideToolbar$,
      this.toolbarBreakpoint$
    ]).pipe(
      map(([shouldHideToolbar, toolbarBreakpoint]) => {
        return shouldHideToolbar ? '0' : toolbarBreakpoint ? '56px' : '64px';
      })
    );

    this.shouldHideFooter$ = this.routerData.activatedRouteData$.pipe(
      map(data => data && data.hideFooter)
    );
    this.routeTitle$ = this.routerData.activatedRouteData$.pipe(
      map(data => data && data.title)
    );
    this.isPageLoading$ = this.pageLoader.isLoading$;
    
    this.userInfo$.pipe(
      filter(x => !!x),
      map(x => x.networkPhoneNumber)).subscribe(networkPhoneNumber => {
       this.phoneNumber = networkPhoneNumber;
    })
  }

  private initCurrentTenant() {
    const currentTenant = this.tenantService.currentTenant;
    const tenantCodeLowerCase = currentTenant.code.toString().toLowerCase();
    this.store.dispatch(actionSettingsChangeTheme({ theme: currentTenant.theme }));
    this.logo = `../../assets/tenants/${tenantCodeLowerCase}/${tenantCodeLowerCase}-logo.png`;
    const favicon = `./assets/tenants/${tenantCodeLowerCase}/${tenantCodeLowerCase}-favicon-16x16.png`
    this._document.getElementById('logoFavicon').setAttribute('href', favicon);
    this.daFlag = '../../assets/dk.svg';
    this.deFlag = '../../assets/de.png';
    this.enFlag = '../../assets/gb.svg';
    this.mkFlag = '../../assets/mk.svg';
    this.sqFlag = '../../assets/al.svg';

    this.language$.pipe(
      take(1),
      filter(language => !language)
    ).subscribe(() => this.store.dispatch(actionSettingsChangeLanguage({ language: currentTenant.preferedLanguage as Language })));
  }

  // TODO: Check if we need this!
  private initMatDialogAfterOpened() {
    this.dialog.afterOpened.pipe(
      distinctUntilChanged(),
      untilDestroyed(this),
      switchMap(() => this.isAuthenticated$),
      distinctUntilChanged(),
      filter(isAuthenticated => !isAuthenticated)
    ).subscribe(() => {
      this.dialog.closeAll();
      this.auth.logout();
      window.location.reload();
    });
  }

getPreferredLanguage(){
   let preferredLanguage: string;

   this.userInfo$.pipe(
      take(1),
      untilDestroyed(this),
      map(data => data && data.preferedLanguage)
   ).subscribe(language => preferredLanguage = language)

   return preferredLanguage
}

  onLogoutClick() {
    this.auth.logout();

    window.location.reload();
  }

  onLanguageSelect(language: string) {
    this.store.dispatch(actionSettingsChangeLanguage({ language: language as Language }));
    this.session.updateLanguage(language);
    this.storageService.setItem('preferredLanguage', language);
    this.cd.detectChanges();
  }

  onRouterOutletActivate(routerOutlet: RouterOutlet) {
    if (routerOutlet && routerOutlet.activatedRouteData) {
      this.routerData.setActivatedRouteData(routerOutlet.activatedRouteData);
    }
  }

  navigateToTerms(){
    this.router.navigate(['/terms'],{ queryParams: { codeType: btoa('BOOKINGS'), codeEntity: btoa('PATIENT') }});
  }
  
  ngOnDestroy() { }
}
