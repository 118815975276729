import { OnDestroy, Injector, Component } from '@angular/core';
import {
  NotificationService,
  SessionService,
  AuthService,
  LocalStorageService,
  CoreDataService,
  PageLoaderService
} from '../core.module';
import { NgxPermissionsService } from 'ngx-permissions';
import { TranslateService } from '@ngx-translate/core';
import { Roles } from '@core/helpers/all-roles';
import { Permissions } from '@core/helpers/all-permissions';
import { MatDateFormats, MAT_DATE_FORMATS } from '@angular/material/core';
import { Moment } from 'moment';
import { AppErrorHandler } from '@core/error-handler/app-error-handler.service';
import { TenantService } from '@core/tenant/tenant.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { IEntity } from './search-select/search-select-component-base';

export class CoreServiceBase implements OnDestroy {
  session: SessionService;
  auth: AuthService;
  storage: LocalStorageService;
  coreData: CoreDataService;
  notify: NotificationService;
  translate: TranslateService;
  permission: NgxPermissionsService;
  errorHandler: AppErrorHandler;
  tenantService: TenantService;
  breakpointObserver: BreakpointObserver;
  pageLoader: PageLoaderService;

  matDateFormats: MatDateFormats;

  Roles = Roles;
  Permissions = Permissions;

  isSmallScreen$: Observable<boolean>;
  isMediumScreen$: Observable<boolean>;
  isLargeScreen$: Observable<boolean>;
  isXLargeScreen$: Observable<boolean>;
  toolbarBreakpoint$: Observable<boolean>;

  constructor(injector: Injector) {
    this.session = injector.get(SessionService);
    this.auth = injector.get(AuthService);
    this.storage = injector.get(LocalStorageService);
    this.coreData = injector.get(CoreDataService);
    this.notify = injector.get(NotificationService);
    this.translate = injector.get(TranslateService);
    this.permission = injector.get(NgxPermissionsService);
    this.errorHandler = injector.get(AppErrorHandler);
    this.tenantService = injector.get(TenantService);
    this.breakpointObserver = injector.get(BreakpointObserver);
    this.pageLoader = injector.get(PageLoaderService);
    this.matDateFormats = injector.get(MAT_DATE_FORMATS);

    this.isSmallScreen$ = this.breakpointObserver.observe('(max-width: 576px)').pipe(map(x => x.matches));
    this.isMediumScreen$ = this.breakpointObserver.observe('(max-width: 768px)').pipe(map(x => x.matches));
    this.isLargeScreen$ = this.breakpointObserver.observe('(max-width: 992px)').pipe(map(x => x.matches));
    this.isXLargeScreen$ = this.breakpointObserver.observe('(max-width: 1200px)').pipe(map(x => x.matches));
    this.toolbarBreakpoint$ = this.breakpointObserver.observe('(max-width: 600px)').pipe(map(x => x.matches));
  }

  formatDate(date: Moment): string {
    return date && date.format(this.matDateFormats.display.dateInput);
  }

  formatTimeSpan(timeSpan: string): string {
    return timeSpan.split(':').slice(0, 2).join(':');
  }

  compareEntityObjects(object1: IEntity, object2: IEntity) {
    return object1 && object2 && object1.id === object2.id;
  }

  getEntityIds(entities: IEntity[]): any[] {
    return entities?.map(x => x?.id) ?? undefined;
  }

  protected deepCompareObjects = (a: any, b: any): boolean => {
    if (Array.isArray(a)) {
      if (Array.isArray(b)) {
        if (a.length !== b.length) throw new Error('parameter arrays must be same length');

        for (let i = 0; i < a.length; i++) {
          if (JSON.stringify(a[i]) !== JSON.stringify(b[i])) return false;
        }

        return true;
      }

      throw new Error('if 1 parametar is array, the other also has to be array');
    }

    return JSON.stringify(a) === JSON.stringify(b)
  }

  ngOnDestroy() { }

}
