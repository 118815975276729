import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { switchMap, take, filter } from 'rxjs/operators';
import { selectSettingsLanguage } from '@core/settings/settings.selectors';
import { AppState } from '@core/core.state';

@Injectable()
export class LanguageInterceptor implements HttpInterceptor {

  constructor(private store: Store<AppState>) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isTenantConfigurationGet = request.url.includes('TenantConfiguration/GetNetwork');
    if(isTenantConfigurationGet) {
      return next.handle(request)
    }
    else {
      return this.store.pipe(select(selectSettingsLanguage)).pipe(
        take(1),
        filter(lang => !!lang),
        switchMap(lang => {
          request = request.clone({
            setHeaders: {
              'Accept-Language': lang
            }
          })
  
          return next.handle(request)
        })
      );
    } 
    
  }

}