import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { NgxPermissionsService } from 'ngx-permissions';
import { Roles } from '@core/helpers/all-roles';
import { Permission } from '@shared/http-clients/http-clients';

@Injectable({
  providedIn: 'root'
})
export class HomeGuard implements CanActivate {

  constructor(
    private permission: NgxPermissionsService,
    private router: Router
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const patientPermission = this.permission.getPermission(Roles.Patient);
    const treatmentProviderDashboardPermission = this.permission.getPermission('Permissions.TreatmentProviderDashboard.Read')
    const ITPermission = this.permission.getPermission(Roles.IT);

    if (!(patientPermission || treatmentProviderDashboardPermission) && !ITPermission ) {
      this.router.navigate(['bookings']);
      return false;
    }
    else if(ITPermission){
      this.router.navigate(['users']);
      return false;
    }

    return true;
  }
}
