export const Permissions = {
  CasesForOtherPatients: {
    Create: 'Permissions.CasesForOtherPatients.Create',
    Read: 'Permissions.CasesForOtherPatients.Read',
    Update: 'Permissions.CasesForOtherPatients.Update',
    Delete: 'Permissions.CasesForOtherPatients.Delete'
  },
  MedicalJournal: {
    Create: 'Permissions.MedicalJournal.Create',
    Read: 'Permissions.MedicalJournal.Read'
  },
  InternalComments: {
    Create: 'Permissions.InternalComments.Create',
    Read: 'Permissions.InternalComments.Read',
    Update: 'Permissions.InternalComments.Update',
    Delete: 'Permissions.InternalComments.Delete'
  },
  Companies: {
    Create: 'Permissions.Companies.Create',
    Read: 'Permissions.Companies.Read',
    Update: 'Permissions.Companies.Update',
    Delete: 'Permissions.Companies.Delete'
  },
  TreatmentProviders: {
    Create: 'Permissions.TreatmentProviders.Create',
    Read: 'Permissions.TreatmentProviders.Read',
    Update: 'Permissions.TreatmentProviders.Update',
    Delete: 'Permissions.TreatmentProviders.Delete'
  },
  Users: {
    Create: 'Permissions.Users.Create',
    Read: 'Permissions.Users.Read',
    Update: 'Permissions.Users.Update',
    Delete: 'Permissions.Users.Delete'
  },
  Assignments: {
    Create: 'Permissions.Assignments.Create',
    Read: 'Permissions.Assignments.Read',
    Update: 'Permissions.Assignments.Update',
    Delete: 'Permissions.Assignments.Delete'
  },
  PortalNotifications: {
    Create: 'Permissions.PortalNotifications.Create',
    Read:  'Permissions.PortalNotifications.Read',
    Update: 'Permissions.PortalNotifications.Update',
    Delete: 'Permissions.PortalNotifications.Delete'
  },
  PastBookings: {
    Create: 'Permissions.PastBookings.Create',
    Read:  'Permissions.PastBookings.Read',
    Update: 'Permissions.PastBookings.Update',
    Delete: 'Permissions.PastBookings.Delete'
  },
  JoinVideoCall: {
    Read:  'Permissions.JoinVideoCall.Read',
  },
  VideoCallPatients: {
    Read:  'Permissions.VideoCallPatients.Read',
    Update:  'Permissions.VideoCallPatients.Update',
  },
  EmailTemplates: {
    Create: 'Permissions.EmailTemplates.Create',
    Read: 'Permissions.EmailTemplates.Read',
    Update: 'Permissions.EmailTemplates.Update',
    Delete: 'Permissions.EmailTemplates.Delete'
  },
  TreatmentProviderDashboard: {
    Create: 'Permissions.TreatmentProviderDashboard.Create',
    Read: 'Permissions.TreatmentProviderDashboard.Read',
    Update: 'Permissions.TreatmentProviderDashboard.Update',
    Delete: 'Permissions.TreatmentProviderDashboard.Delete'
  },
  Cases: {
    Create: 'Permissions.Cases.Create',
    Read: 'Permissions.Cases.Read',
    Update: 'Permissions.Cases.Update',
    Delete: 'Permissions.Cases.Delete'
  },
  Bookings: {
    Create: 'Permissions.Bookings.Create',
    Read: 'Permissions.Bookings.Read',
    Update: 'Permissions.Bookings.Update',
    Delete: 'Permissions.Bookings.Delete'
  }
};
