import { Injectable } from '@angular/core';
import {
  UserInfoClient,
  UserInfo,
  ShortPermissionModel
} from '@shared/http-clients/http-clients';
import { BehaviorSubject, Observable } from 'rxjs';
import { take, finalize, tap } from 'rxjs/operators';
import { NgxPermissionsService } from 'ngx-permissions';
import { TokenData } from '@core/auth/auth.models';
import { LocalStorageService } from '@core/local-storage/local-storage.service';

export const USER_INFO_KEY = 'USER_INFO';
export const AUTH_KEY = 'AUTH_DATA';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  private _userInfo$ = new BehaviorSubject<UserInfo>(undefined);
  private _isLoadingUserInfo$ = new BehaviorSubject<boolean>(false);

  get userInfo$(): Observable<UserInfo> {
    return this._userInfo$.asObservable();
  }

  get isLoadingUserInfo$(): Observable<boolean> {
    return this._isLoadingUserInfo$.asObservable();
  }

  constructor(
    private userInfoClient: UserInfoClient,
    private permissionService: NgxPermissionsService,
    private storageService: LocalStorageService
  ) {}

  fetchUserInfo() {
    this._isLoadingUserInfo$.next(true);

    return this.userInfoClient.get().pipe(
      take(1),
      finalize(() => this._isLoadingUserInfo$.next(false)),
      tap(userInfo => this.setUserInfo(userInfo))
    );
  }

  setUserInfo(userInfo: UserInfo) {
    this.loadRolesAndPermissions(userInfo);
    this._userInfo$.next(userInfo);
  }

  clearData() {
    this._userInfo$.next(undefined);
  }

  updateLanguage(language: string){
    if(language){
      this.userInfoClient.updateUserLanguage(language).subscribe(x=>{
        const loginInfo = this.storageService.getTypedItem<TokenData>(AUTH_KEY); 
        const userInfo = UserInfo.fromJS(JSON.parse(loginInfo.userInfo));
        userInfo.preferedLanguage = language
        loginInfo.userInfo = JSON.stringify(userInfo)
        this.storageService.setItem(AUTH_KEY, loginInfo);
        this.setUserInfo(userInfo);
      });
    }
  }

  // todo move to helper
  private isNullOrWhitespace(input: string): boolean {
    return !input || !input.trim();
  }

  private loadRolesAndPermissions(userInfo: UserInfo) {
    let permissions: Map<string, string[]> = new Map<string, string[]>();

    if (userInfo.userPermissions) {
      userInfo.userPermissions.forEach(permission => {
        permissions.set(
          permission.name,
          this.mapShortPermissionModelToNgxPermissions(permission)
        );
      });
    }

    let array: string[] = [];

    if (userInfo.roles) {
      userInfo.roles.forEach(role => {
        array.push(`Roles.${role.code}`);

        if (role.permissions) {
          role.permissions.forEach(permission => {
            permissions.set(
              permission.name,
              this.mapShortPermissionModelToNgxPermissions(permission)
            );
          });
        }
      });
    }

    for (let key of permissions.keys()) {
      array.push(...permissions.get(key));
    }

    this.permissionService.loadPermissions(array);
  }

  private mapShortPermissionModelToNgxPermissions(
    permission: ShortPermissionModel
  ): string[] {
    let permissions: string[] = [];

    if (permission.read) {
      permissions.push(`Permissions.${permission.name}.Read`);
    }

    if (permission.create) {
      permissions.push(`Permissions.${permission.name}.Create`);
    }

    if (permission.update) {
      permissions.push(`Permissions.${permission.name}.Update`);
    }

    if (permission.delete) {
      permissions.push(`Permissions.${permission.name}.Delete`);
    }

    return permissions;
  }
}
