import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { TenantService } from '@core/tenant/tenant.service';

@Injectable({
  providedIn: 'root'
})
export class SelfRegisterGuard implements CanActivate {

  constructor(
    private tenantService: TenantService,
    private router: Router
  ) { }

  canActivate(): boolean {

    if (this.tenantService.currentTenant.selfRegistration) 
      return true;

    this.router.navigate(['/']);

    return false;
  }
  
}
