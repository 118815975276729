import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


export interface DialogConfig {
  title?: string,
  content?: string | Component,
  contentHtml?: string,
  cancel?: string,
  confirm?: string,
  close?: string,
}

@Component({
  selector: 'kailo-confirm-dialog-notification',
  templateUrl: './confirm-dialog-notification.component.html',
  styleUrls: ['./confirm-dialog-notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmDialogNotificationComponent {
  get dialog(): DialogConfig {
    return this.data
}

constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogConfig,
    public dialogRef: MatDialogRef<ConfirmDialogNotificationComponent>
) {}

}
