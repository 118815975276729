import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'

export interface CanComponentDeactivate {
    canDeactivate: () => Observable<boolean> | boolean
}

@Injectable()
/**
 * guard for checking if it is allowed to navigate from the current route.
 * example: check if a form has changes, and ask the user if he wants to discard the changes
 *
 * usage:
 * - implement  "canDeactivate: [CanDeactivateGuard]" in the route
 * - implement interface CanComponentDeactivate in the component
 * - implement "canDeactivate(): Observable<boolean> | boolean" in the component
 *
 */
export class CanDeactivateGuard implements CanDeactivate<CanComponentDeactivate> {
    canDeactivate(
        component: CanComponentDeactivate,
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ) {

        const url: string = state.url;

        return component.canDeactivate ? component.canDeactivate() : true
    }
}
