import { Injector, ElementRef, ChangeDetectorRef } from '@angular/core';
import { CoreServiceBase } from './core-service-base';
import { Location } from '@angular/common';

export class CoreComponentBase extends CoreServiceBase {
  elementRef: ElementRef;
  cd: ChangeDetectorRef;
  browserLocation: Location;

  constructor(injector: Injector) {
    super(injector);
    this.elementRef = injector.get(ElementRef);
    this.cd = injector.get(ChangeDetectorRef);
    this.browserLocation = injector.get(Location);
  }

  navigateBack() {
    this.browserLocation.back();
  }
}