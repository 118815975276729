import { Injectable } from '@angular/core'
import { SwUpdate, SwPush } from '@angular/service-worker'
import { environment } from 'environments/environment';

@Injectable()
export class PwaService {
  private readonly _checkNewVersionInterval = 3 * 60 * 1000;
  private _isNewVersionAvailable = false;

  constructor(
    private swUpdate: SwUpdate,
    private swPush: SwPush
  ) { }

  init() {
    this.swUpdate.available.subscribe(() => this._isNewVersionAvailable = true);
    this.swPush.messages.subscribe(v => { window.alert(v) })

    if (environment.production) {
      setInterval(() => {
        this.swUpdate.checkForUpdate().then();
      }, this._checkNewVersionInterval);
    }

  }

  checkAndUpdateVersion() {
    if (this._isNewVersionAvailable) {
      this._isNewVersionAvailable = false;
      window.location.reload();
    }
  }
}

