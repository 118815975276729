import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { map, distinctUntilChanged } from 'rxjs/operators';import { LocalStorageService } from '@core/core.module';
;

@Injectable({
  providedIn: 'root'
})
export class TermsAndConditionsGuard implements CanActivate {
  constructor(
    private auth: AuthService,
    private router: Router,
    private storageService: LocalStorageService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.auth.isAuthenticated$.pipe(distinctUntilChanged()).pipe(
      map(isAuthenticated => {
        var isRegister= this.storageService.getItem('isRegister') ? true : false;
        if (isAuthenticated || isRegister) return true;

        this.auth.logout();
        this.router.navigate(['login']);

        return false;
      })
    )
  }
  
}
