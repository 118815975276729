export interface Tenant {
  code?: TenantCode;
  urlPattern?: string;
  theme?: string;
  preferedLanguage?: string;
  useNemId?: boolean;
  appTitle?: string;
  useVideoFeature?: boolean;
  selfRegistration?: boolean;
  defaultClinicProfile?: boolean;
  clinicAccessToAssignments?: boolean;
  clinicSearchAccess?: boolean;
}

export enum TenantCode {
  Kailo = 'KAILO' as any,
  PrimaCare = 'PRIMACARE' as any
}