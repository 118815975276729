import { Injectable } from '@angular/core';
import {
  HttpRequest, HttpHandler, HttpEvent,
  HttpInterceptor, HttpErrorResponse, HttpParams
} from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { AuthService, tokenEndpoint } from '../auth/auth.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  private refreshTokenInProgress = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(private authService: AuthService) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const isUrlTokenEndpoint = request.url.includes(tokenEndpoint) || request.url.includes('ForgotPassword');

    let hasRefreshTokenGrantType: boolean;
    let refreshTokenValue: string;
    const isTenantConfigurationGet = request.url.includes('TenantConfiguration/GetNetwork');

    if (isUrlTokenEndpoint && request.body instanceof HttpParams) {
      const body: HttpParams = request.body;
      hasRefreshTokenGrantType = body.has('grant_type') && body.get('grant_type') === 'refresh_token';
      refreshTokenValue = hasRefreshTokenGrantType && body.has('refresh_token') && body.get('refresh_token');
    }

    if (request.url.includes('logout')) {
      this.refreshTokenInProgress = false;
      this.refreshTokenSubject.next(null);
    }

    if (!refreshTokenValue && !isUrlTokenEndpoint && !isTenantConfigurationGet) {
      request = this.addAuthenticationToken(request);
    }

    return next.handle(request).pipe(
      catchError((response: any) => {
        if (response instanceof HttpErrorResponse) {
          // this.loaderService.display(false);
          // We don't want to refresh token for some requests like login or refresh token itself
          // So we verify url and we throw an error if it's the case
          if (isUrlTokenEndpoint || request.url.includes('logout')) {
            return throwError(response);
          }
          // If error status is different than 401 we want to skip refresh token
          // So we check that and throw the error if it's the case
          if (response.status !== 403 && response.status !== 401) {
            // this.errorHandler.handleError(response);
            return throwError(response);
          }
          return this.handleRefreshToken(request, next);
        }

        return throwError(response);
      })
    );
  }

  private handleRefreshToken(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.refreshTokenInProgress) {
      return this.refreshTokenSubject.pipe(
        filter(result => result !== null),
        take(1),
        switchMap(() => next.handle(this.addAuthenticationToken(request)))
      );
    } else {
      this.refreshTokenInProgress = true;
      this.refreshTokenSubject.next(null);
      const tokenData = this.authService.tokenData;

      return this.authService.refreshToken(tokenData).pipe(
        switchMap(response => {
          this.refreshTokenInProgress = false;
          this.refreshTokenSubject.next(response);
          return next.handle(this.addAuthenticationToken(request));
        }),
        catchError(() => {
          this.refreshTokenInProgress = false;
          this.authService.logout();
          window.location.reload();
          return next.handle(request);
        })
      );
    }
  }

  private addAuthenticationToken(request: HttpRequest<any>): HttpRequest<any> {
    const tokenData = this.authService.tokenData;

    if (!tokenData) return request;

    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${tokenData.access_token}`
      },
      withCredentials: true
    });
  }
}
